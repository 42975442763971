import { CardContent, Tab, Tabs } from '@material-ui/core'
import { Theme, withStyles, WithStyles } from '@material-ui/core/styles'
import { CustomerType, IAdminCustomer } from '@omnicar/sam-types'
import classNames from 'classnames'
import { Card } from 'components/Mui/Card'
import { Panel, PanelContent, PanelHeader, PanelTitle } from 'components/Mui/Panel'
import React, { ChangeEvent } from 'react'
import { contractFlowInitialState } from 'reducers/contractFlow/contractFlowInitialState'
import { createPanelStyles, theme as customTheme } from 'theme'
import { t } from 'translations/translationFunctions'
import { ContractFlowActivePanel, ContractFlowType } from 'types/contractFlow'
import ContractFlowCustomerForm from './Form'
import ContractFlowCustomerLookup from './Lookup'

interface IOwnProps {
  active: ContractFlowActivePanel
  activeEnum: ContractFlowActivePanel
  flow: ContractFlowType
  locked: boolean
  freeContract: boolean
  dealerPaidWarranty?: boolean
  onChange: (value: IAdminCustomer, valid: boolean) => void
  onLockedChange: (locked: boolean) => void
  value: IAdminCustomer
  valid: boolean
  customerUpdates: number
}

type TProps = WithStyles<typeof styles> & IOwnProps

interface IState {
  activeTab: CustomerType
}

const styles = (theme: Theme) =>
  createPanelStyles(theme, {
    freeContractActive: {
      borderLeftColor: customTheme.palette.freeContract[500],
    },
    freeContractTab: {
      backgroundColor: customTheme.palette.freeContract[500],
    },
    cardDealerPaid: {
      borderLeftColor: customTheme.palette.primary[500],
    },
    dealerPaidTab: {
      backgroundColor: customTheme.palette.primary[500],
    },
  })

class ContractFlowCustomer extends React.Component<TProps, IState> {
  private tabs: Record<CustomerType, string> = {
    PRIVATE: t('Private'),
    BUSINESS: t('Company'),
  }

  constructor(props: TProps) {
    super(props)

    this.state = {
      activeTab: props.value.customerType,
    }
  }

  public render() {
    const { classes, active, activeEnum, locked, flow, value, freeContract, dealerPaidWarranty } = this.props
    const { activeTab } = this.state
    const isAdjust = flow === 'ADJUST'
    const isActive = active >= activeEnum || !isAdjust
    const isLocked = !active || locked || isAdjust
    const cardClass = dealerPaidWarranty ? classes.cardDealerPaid : freeContract && classes.freeContractActive
    const tabClass = dealerPaidWarranty ? classes.dealerPaidTab : freeContract ? classes.freeContractTab : ''

    return (
      <Panel disabled={!isActive}>
        <PanelHeader>
          <PanelTitle>{t('Contact information')}</PanelTitle>
        </PanelHeader>
        <PanelContent>
          {!isAdjust && (
            <ContractFlowCustomerLookup
              filledFromSearch={locked || value.id ? true : false}
              onClear={this.handleLookupClear}
              onChange={this.handleLookupChange}
              customer={value}
              customerUpdates={this.props.customerUpdates}
            />
          )}
          <Card className={isActive ? classNames(classes.cardActive, cardClass) : ''}>
            <CardContent style={{ paddingTop: 0 }}>
              <Tabs
                value={activeTab}
                onChange={this.handleTabChange}
                data-e2e={'ContractFlowCustomer__tabs'}
                classes={{ indicator: tabClass }}
              >
                {Object.keys(this.tabs).map((t) => (
                  <Tab key={t} disabled={isLocked} value={t} label={this.tabs[t]} />
                ))}
              </Tabs>
              {activeTab === 'BUSINESS' ? (
                <ContractFlowCustomerForm
                  type={'BUSINESS'}
                  values={value}
                  onChange={this.handleChange}
                  onLookupChange={this.handleLookupChange}
                  filledFromSearch={isAdjust || locked}
                  active={isActive}
                  customerUpdates={this.props.customerUpdates}
                />
              ) : (
                <ContractFlowCustomerForm
                  type={'PRIVATE'}
                  values={value}
                  onChange={this.handleChange}
                  onLookupChange={this.handleLookupChange}
                  filledFromSearch={isAdjust || locked}
                  active={isActive}
                  customerUpdates={this.props.customerUpdates}
                />
              )}
            </CardContent>
          </Card>
        </PanelContent>
      </Panel>
    )
  }

  private handleTabChange = (event: ChangeEvent<any>, type: CustomerType) => {
    this.setState({ activeTab: type })
    // Change CustomerType of IAdminCustomer
    const updatedCustomer: IAdminCustomer = { ...this.props.value, customerType: type }
    this.props.onChange(updatedCustomer, this.props.valid)
  }

  private handleChange = (customer: IAdminCustomer, valid: boolean) => {
    this.props.onChange(customer, valid)
    // this.blurCustomerForm()
  }

  private handleLookupChange = (customer: IAdminCustomer) => {
    this.setState({ activeTab: customer.customerType }, () => {
      this.props.onLockedChange(true)
      this.props.onChange(customer, true)
    })
  }

  private handleLookupClear = () => {
    this.props.onLockedChange(false)
    this.props.onChange({ ...contractFlowInitialState.customer }, false)
  }

  /**
   * Makes a "focus out" (blur) on any particular field (for example, prevents
   * the red error helperText to untrigger after an autofill of a field.
   */
  // private blurCustomerForm = () => {
  //   const input = document.querySelector('input')
  //   input!.focus()
  // }
}

export default withStyles(styles)(ContractFlowCustomer)
