import { CardContent } from '@material-ui/core'
import { createStyles, Theme, WithStyles, withStyles } from '@material-ui/core/styles'
import { HelpOutline as HelperIcon } from '@material-ui/icons'
import classNames from 'classnames'
import { Card } from 'components/Mui/Card'
import { Panel, PanelContent } from 'components/Mui/Panel'
import React from 'react'
import { compose } from 'recompose'

interface IOwnProps {
  children: React.ReactNode
}

type TProps = WithStyles<typeof styles> & IOwnProps

const styles = ({ spacing, palette }: Theme) =>
  createStyles({
    card: {
      display: 'flex',
      backgroundColor: '#EAF9F9',
      marginTop: 0,
      marginBottom: 0,
      paddingTop: 0,
      paddingBottom: 0,
    },
    iconContent: {
      paddingLeft: spacing(1.5),
      paddingRight: spacing(2),
      marginTop: 'auto',
      marginBottom: 'auto',
    },
    icon: {
      fontSize: 32,
      color: palette.primary.main,
      opacity: 0.9,
    },
  })

const HelperSection: React.SFC<TProps> = ({ classes, children }) => {
  return (
    <Panel>
      <PanelContent>
        <Card className={classNames(classes.card)}>
          <CardContent className={classNames(classes.iconContent)}>
            <HelperIcon className={classNames(classes.icon)} />
          </CardContent>
          <CardContent>{children}</CardContent>
        </Card>
      </PanelContent>
    </Panel>
  )
}

export default compose<TProps, IOwnProps>(withStyles(styles))(HelperSection)
