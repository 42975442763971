import { createStyles, TextField, Theme, WithStyles, withStyles } from '@material-ui/core'
import { CustomerType, IAdminCustomer, TIsoCountry } from '@omnicar/sam-types'
import { getCustomerVatInfo, getUser, lookupUser } from 'api/api'
import Typography from 'components/Typography'
import { CONFIG } from 'config'
import hideEANFieldForCompanyAddress from 'config/hideEANFieldForCompanyAddress'
import { Formik, FormikProps } from 'formik'
import memoize from 'lodash.memoize'
import React, { Fragment } from 'react'
import { t } from 'translations/translationFunctions'
import { CustomerFieldName, validateCustomer } from 'utils/formik'
import { getProviderCountry } from 'utils/localStorage'
import { validEmail } from 'utils/regex'
import { firstCharUpper } from 'utils/string'
import ConfirmDialog from '../../../../../ConfirmDialog'
import { getCityByZip } from 'utils/zipCity'

const VALIDATOR_ENFORCER_MS = {
  interval: 500,
  timeout: 500,
}

export interface ILookupCustomerWithEmailResponse {
  isValidEmail: boolean
  isExistingCustomer: boolean // If the email already belongs to an existing customer.
  statusCode: number
  customerError: null | CustomerError
  customer: undefined | IAdminCustomer
}

/**
 * 'Enter-customer-email-for-contract-mode' - Use this at contract checkout page.
 * 'Edit-customer-email-on-contract-mode' - Use this when editing customer on an existing contract.
 */
export type TErrorReportModeForLookupCustomerEmail =
  | 'Enter-customer-email-for-contract-mode'
  | 'Edit-customer-email-on-contract-mode'

export const lookupCustomerStatusOfEmail = async (
  email: string,
  errorReportMode = 'Enter-customer-email-for-contract-mode',
): Promise<ILookupCustomerWithEmailResponse> => {
  const response: ILookupCustomerWithEmailResponse = {
    isValidEmail: false,
    isExistingCustomer: false,
    statusCode: 0,
    customerError: null,
    customer: undefined,
  }

  // Check if the email address is already in use.
  if (validEmail(email)) {
    response.isValidEmail = true

    // At contact information form at Customer & Payment.
    const userExists = await lookupUser(email)

    const { statusCode } = userExists
    if (userExists.data) {
      response.isExistingCustomer = true
      response.customer = userExists?.data

      // User exists for this provider.
      response.customerError = 'CUSTOMER_EXISTS'
    } else if (statusCode) {
      response.statusCode = statusCode

      if (userExists.errorData?.message === 'GENERIC_CUSTOMER_EMAIL_ALREADY_EXISTS_AS_USER') {
        response.customerError = 'CUSTOMER_EMAIL_ALREADY_EXISTS_AS_USER'
        return response
      }

      if (statusCode === 403) {
        // The user is in use already (probably by other provider).
        response.customerError = 'FOREIGN_CUSTOMER_EXISTS'
      } else if (statusCode === 404) {
        // if (errorReportMode === 'Edit-customer-email-on-contract-mode') {
        //   // const isExternalContract: boolean = this.state.contractSpecifics.contractType === 'EXTERNAL'
        //   notify.error({
        //     message: t('No customer with this email was found. Cannot update customer to a non existing customer'),
        //   })
        // } else {
        //   // Report nothing, since a new customer will be created with this email for this contract.
        // }
      } else {
        const customerExists = await getUser(email)
        if (customerExists.data) {
          response.customerError = 'CUSTOMER_EXIST_AS_USER'
        }
      }
    }
  }

  return response
}

interface IOwnProps {
  onChange: (customer: IAdminCustomer, valid: boolean) => void
  onLookupChange: (customer: IAdminCustomer) => void
  values: IAdminCustomer
  filledFromSearch: boolean
  active: boolean
  type: CustomerType
  customerUpdates: number
}

type TProps = IOwnProps & WithStyles<typeof styles>

const styles = (theme: Theme) =>
  createStyles({
    subheading: {
      marginTop: theme.spacing(3),
      marginBottom: 0,
    },
  })

type CustomerError =
  | 'NONE'
  | 'CUSTOMER_EXISTS'
  | 'FOREIGN_CUSTOMER_EXISTS'
  | 'CUSTOMER_VAT_3RD_PARTY'
  | 'CUSTOMER_VAT_DB'
  | 'CUSTOMER_EMAIL_ALREADY_EXISTS_AS_USER' // The customer's email already exists as a (Seller/Admin/SuperAdmin/...) -User.
  | 'CUSTOMER_EXIST_AS_USER'

interface IState {
  customerError: CustomerError
  isDialogOpen: boolean
  validCustomer: boolean
  emailLookupCustomer?: IAdminCustomer
  vatLookupCustomer?: IAdminCustomer
  initialValues: IAdminCustomer
  providerCountry: string | null
  isFieldBusinessCVRValidated: boolean // CVR if business.
  isFieldPrivateEmailValidated: boolean // Email if private.
  isCustomerEmailDoesNotExistsAsUserValidated: boolean // That customer's email does not exist as a (Seller/Admin/SuperAdmin/...) -User.
}

export class ContractFlowCustomerForm extends React.Component<TProps, IState> {
  private country: TIsoCountry = 'DK'
  private refInputCVR: any // Ref for field CVR in "Business" tab.
  private refInputEmail: any // Ref for field email in "Private" tab.
  // private delayedFunc: (() => void) | undefined = undefined
  private delayedFunc: any = undefined

  private fieldLabels: () => Record<CustomerFieldName, string> = memoize(() => ({
    cvr: t('CVR'),
    companyName: t('Company name'),
    email: t('Email'),
    name: t('Name'),
    ean: t('EAN'),
    phone: t('Phone'),
    address: t('Address'),
    zip: t('Postal code'),
    city: t('City'),
  }))

  private emailValidatorEnforcer = () => {
    if (this.delayedFunc) {
      const env = CONFIG.env
      env !== 'production' &&
        console.debug('Triggering delayedFunc for emailValidatorEnforcer (this msg not shown in live)')

      this.delayedFunc()
    }
  }

  public constructor(props: TProps) {
    super(props)
    const { values, type } = this.props

    setInterval(() => {
      this.emailValidatorEnforcer()
    }, VALIDATOR_ENFORCER_MS.interval)

    this.state = {
      customerError: 'NONE',
      isDialogOpen: false,
      validCustomer: false,
      initialValues: { ...values },
      providerCountry: getProviderCountry(),
      isFieldBusinessCVRValidated: false,
      isFieldPrivateEmailValidated: false,
      isCustomerEmailDoesNotExistsAsUserValidated: false,
    }

    // Perform an initial validation.
    let valid = true
    if (!values.id) {
      const errors = this.validate(values)
      valid = Object.keys(errors).length === 0
    }
    this.props.onChange({ ...values, customerType: type }, valid)
  }

  public componentDidMount() {
    const country = getProviderCountry()

    if (country) {
      this.country = country as TIsoCountry
    }

    this.refInputCVR?.focus()
    this.refInputEmail?.focus()
  }

  public componentDidUpdate(oldProps: TProps) {
    if (oldProps.type !== this.props.type) {
      const { type, values } = this.props

      // Revalidate on tab change.
      const errors = this.validate(values)
      const valid = Object.keys(errors).length === 0

      this.props.onChange({ ...values, customerType: type }, valid)
    }
    if (oldProps.values.id && !this.props.values.id) {
      // Check if the customer has been reset and set initialValues to whatever
      // is incoming (probably an empty customer).
      this.setState({ initialValues: { ...this.props.values } })
    }
  }

  public render() {
    const { classes, type, values } = this.props

    // Trick to reset form if customer is found from search result.
    const initialValues: IAdminCustomer = values.id ? values : this.state.initialValues
    const providerCountry = !this.state.providerCountry ? '' : this.state.providerCountry

    return (
      <Formik
        initialValues={initialValues}
        validate={this.validate}
        onSubmit={this.fakeSubmit}
        enableReinitialize={true}
      >
        {(formikProps: FormikProps<IAdminCustomer>) => (
          <main data-e2e="ContractFlowCustomerForm">
            {type === 'BUSINESS' ? (
              <Fragment>
                {this.getField('cvr', formikProps)}
                {this.getField('companyName', formikProps)}
                {this.getField('address', formikProps)}
                {this.getField('zip', formikProps)}
                {this.getField('city', formikProps)}
                {!hideEANFieldForCompanyAddress[providerCountry] && this.getField('ean', formikProps)}
                <Typography className={classes.subheading} variant="subheading">
                  {t('Contact')}
                </Typography>
                {this.getField('email', formikProps)}
                {this.getField('phone', formikProps)}
                {this.getField('name', formikProps)}
              </Fragment>
            ) : (
              <Fragment>
                {this.getField('email', formikProps)}
                {this.getField('name', formikProps)}
                {this.getField('phone', formikProps)}
                {this.getField('address', formikProps)}
                {this.getField('zip', formikProps)}
                {this.getField('city', formikProps)}
              </Fragment>
            )}
            {this.renderUseExistingCustomerDialog(formikProps.setFieldValue)}
          </main>
        )}
      </Formik>
    )
  }

  private getField = (field: CustomerFieldName, formikProps: FormikProps<IAdminCustomer>) => {
    const { active, filledFromSearch, type } = this.props
    const {
      isFieldBusinessCVRValidated,
      isFieldPrivateEmailValidated,
      isCustomerEmailDoesNotExistsAsUserValidated,
    } = this.state

    // console.log('\n-----------')
    // console.log('isFieldBusinessCVRValidated = ' + isFieldBusinessCVRValidated)
    // console.log('isFieldPrivateEmailValidated = ' + isFieldPrivateEmailValidated)
    // console.log('isCustomerEmailDoesNotExistsAsUserValidated = ' + isCustomerEmailDoesNotExistsAsUserValidated)

    let notValidatedBusinessCVR,
      notValidatedPrivateEmail: boolean | null = null
    if (!isFieldBusinessCVRValidated) {
      if (type === 'BUSINESS' && field === 'cvr') {
        notValidatedBusinessCVR = false
      } else {
        notValidatedBusinessCVR = true
      }
    }

    if (!isFieldPrivateEmailValidated) {
      if (type === 'PRIVATE' && field === 'email') {
        notValidatedPrivateEmail = false
      } else {
        notValidatedPrivateEmail = true
      }
    }

    const fieldLabel = this.fieldLabels()[field]
    const { touched, errors, handleBlur, handleChange, setFieldValue, values } = formikProps
    const disabled = !active || filledFromSearch
    const value = values[field]

    return (
      <TextField
        tabIndex={1}
        inputRef={(el: any) => {
          if (type === 'BUSINESS' && field === 'cvr') {
            this.refInputCVR = el
          } else if (type === 'PRIVATE' && field === 'email') {
            this.refInputEmail = el
          }
        }}
        name={field}
        fullWidth={true}
        disabled={
          disabled ||
          (type === 'BUSINESS' && !!notValidatedBusinessCVR) ||
          (type === 'PRIVATE' && !!notValidatedPrivateEmail)
        }
        label={fieldLabel}
        helperText={!filledFromSearch && touched[field] && errors[field]}
        error={!filledFromSearch && errors[field] && touched[field] ? true : false}
        value={value}
        margin="dense"
        onKeyUp={async (event: KeyboardEvent, elem: any) => {
          // if (event.key === 'Tab' || event.key === 'Enter') {
          //   handleBlur(event)
          //   this.validateCVROrEmail(field, values)
          // }

          this.delayedFunc = () => {
            setTimeout(() => {
              this.delayedFunc = undefined
              handleBlur(event)
              this.validateCVROrEmail(field, values)
            }, VALIDATOR_ENFORCER_MS.timeout)
          }

          handleBlur(event)

          // Note: Below, this is function commented out, we do this via the this.delayedFunc instead.
          // this.validateCVROrEmail(field, values)

          const valuesToStore = { ...values }
          if (field === 'zip') {
            const city = await this.fillOutCity(value, setFieldValue)
            if (city) {
              valuesToStore.city = city
            }
          }

          const errors = validateCustomer(valuesToStore, type, !isCustomerEmailDoesNotExistsAsUserValidated)
          const valid = Object.keys(errors).length === 0

          // Persist to redux (better in onBlur than onChange/validate).
          this.props.onChange({ ...valuesToStore, customerType: type }, valid)
          this.setState({ validCustomer: valid })
        }}
        // tslint:disable-next-line:jsx-no-lambda
        onBlur={async (e) => {
          handleBlur(e)
          e.preventDefault()
          this.validateCVROrEmail(field, values)

          const valuesToStore = { ...values }
          if (field === 'zip') {
            const city = await this.fillOutCity(value, setFieldValue)
            if (city) {
              valuesToStore.city = city
            }
          }

          const valueString = value ? `${value}` : undefined
          // First letter of address, city and name should be Uppercase.
          if (
            valueString &&
            valueString.length > 0 &&
            ['address', 'city', 'name'].includes(field) &&
            valueString.charAt(0).toUpperCase() !== valueString.charAt(0)
          ) {
            const upperValue = firstCharUpper(valueString)
            setFieldValue(field, upperValue)
            valuesToStore[field] = upperValue
          }

          const errors = validateCustomer(valuesToStore, type, !isCustomerEmailDoesNotExistsAsUserValidated)
          const valid = Object.keys(errors).length === 0

          // Persist to redux (better in onBlur than onChange/validate).
          this.props.onChange({ ...valuesToStore, customerType: type }, valid)
          this.setState({ validCustomer: valid })
        }}
        onChange={handleChange}
      />
    )
  }

  private validateCVROrEmail = async (field: CustomerFieldName, values: IAdminCustomer) => {
    const { type } = this.props

    if (field === 'email') {
      await this.verifyEmailAddress(values.email)
      type === 'PRIVATE' && this.setState({ isFieldPrivateEmailValidated: true })
    }

    if (field === 'cvr') {
      // Only do vat lookup if provider is Danish or Norwegian.
      const providerCounty = getProviderCountry()
      if (providerCounty && (providerCounty.toUpperCase() === 'DK' || providerCounty.toUpperCase() === 'NO')) {
        this.fillOutVatInfo(`${values.cvr}`)
      }
      this.setState({ isFieldBusinessCVRValidated: true })
    }
  }

  // Formik requires a submit callback - we just don't use it for now
  // tslint:disable-next-line:no-empty
  private fakeSubmit = () => {}

  private validate = (values: IAdminCustomer) => {
    const { type } = this.props
    const { isCustomerEmailDoesNotExistsAsUserValidated } = this.state

    const errors = validateCustomer(values, type, !isCustomerEmailDoesNotExistsAsUserValidated)
    const valid = Object.keys(errors).length === 0

    // We only tell Redux if form validity changes.
    if (this.state.validCustomer !== valid) {
      this.props.onChange({ ...values, customerType: type }, valid)
    }
    return errors
  }

  private fillOutCity = async (
    zipcode: string | number | undefined,
    setFieldValue: (field: keyof IAdminCustomer & string, value: any, shouldValidate?: boolean) => void,
  ) => {
    if (zipcode && `${zipcode}`.length > 0) {
      const zipCity = await getCityByZip(this.country, zipcode)
      if (zipCity) {
        setFieldValue('city', zipCity)
        return zipCity
      }
    }
    return
  }

  private fillOutVatInfo = async (cvr: string) => {
    if (cvr && cvr.length >= 8) {
      const customer = await getCustomerVatInfo(cvr)
      if (!customer.data) {
        return
      }

      if (customer.data.vatLookup === 'REMOTE') {
        // Customer Company Information fetched from 3rd party.
        this.setState({
          customerError: 'CUSTOMER_VAT_3RD_PARTY',
          isDialogOpen: true,
          vatLookupCustomer: customer.data,
        })
      } else if (customer.data!.vatLookup === 'INTERNAL') {
        // Customer Company Information fetched database.
        this.setState({
          customerError: 'CUSTOMER_VAT_DB',
          isDialogOpen: true,
          vatLookupCustomer: customer.data,
        })
      }
      // Updated 22-04-08 we don't want to show error message if statuscode is 404, it's no longer relevant
      // Added disableErrorHandling: true on the request (getCustomerVatInfo).
      // (If the statusCode is 404, the customer does not exist)
    }
  }

  private verifyEmailAddress = async (email: string) => {
    // Check if the email address is already in use.
    const response = await lookupCustomerStatusOfEmail(email)

    switch (response && response.customerError) {
      case 'CUSTOMER_EXISTS':
        this.setState({
          customerError: 'CUSTOMER_EXISTS',
          isDialogOpen: true,
          emailLookupCustomer: response.customer,
          isFieldPrivateEmailValidated: false,
        })
        break
      case 'FOREIGN_CUSTOMER_EXISTS':
        this.setState({
          customerError: 'FOREIGN_CUSTOMER_EXISTS',
          isDialogOpen: true,
          isFieldPrivateEmailValidated: false,
        })
        break
      case 'CUSTOMER_EXIST_AS_USER':
        this.setState({
          customerError: 'CUSTOMER_EXIST_AS_USER',
          isDialogOpen: true,
          isFieldPrivateEmailValidated: false,
        })
        break
      case 'CUSTOMER_EMAIL_ALREADY_EXISTS_AS_USER':
        this.setState({
          customerError: 'CUSTOMER_EMAIL_ALREADY_EXISTS_AS_USER',
          isDialogOpen: true,
          isCustomerEmailDoesNotExistsAsUserValidated: false,
        })
        break
      default:
        console.warn('verifyEmailAddress(..): case else')
        console.debug('response.customerError:')
        console.debug(response.customerError)
        console.debug('response:')
        console.debug(response)
        if (response && response.isValidEmail && response.statusCode === 404) {
          this.setState({ isFieldPrivateEmailValidated: true, isCustomerEmailDoesNotExistsAsUserValidated: true })
        }
    }
  }

  public renderUseExistingCustomerDialog = (
    setFieldValue: (field: keyof IAdminCustomer & string, value: any, shouldValidate?: boolean) => void,
  ): JSX.Element | null => {
    const { customerError, isDialogOpen } = this.state

    let titleTrl: string = ''
    let labelTrlConfirmButton: string = ''
    let onConfirmAction: any = null
    let labelTrlCancelButton: string = ''
    let onCancelAction: any = null

    switch (customerError) {
      case 'CUSTOMER_EXISTS':
        titleTrl = t('The customer already exists. Do you want to use this customer?')
        labelTrlConfirmButton = t('YES')
        onConfirmAction = () => {
          this.handleUseExistingCustomer(this.state.emailLookupCustomer)
          this.closeDialog()
          this.setState({ isFieldPrivateEmailValidated: true })
        }
        labelTrlCancelButton = t('NO')
        onCancelAction = () => {
          this.rejectExistingCustomer(setFieldValue)
          this.closeDialog()
        }
        break
      case 'CUSTOMER_VAT_3RD_PARTY':
        titleTrl = t('The customer was found in the VAT register. Do you want to use the information we found?')
        labelTrlConfirmButton = t('YES')
        onConfirmAction = () => {
          this.acceptCustomerVatInfo(setFieldValue)
          this.closeDialog()
        }
        labelTrlCancelButton = t('NO')
        onCancelAction = () => {
          this.rejectExistingCustomer(setFieldValue)
          this.closeDialog()
        }
        break
      default:
    }

    if (!titleTrl) {
      return null
    }

    return (
      <ConfirmDialog
        open={isDialogOpen}
        titleTrl={titleTrl}
        captionTrlConfirmButton={labelTrlConfirmButton}
        onConfirm={onConfirmAction}
        captionTrlCancelButton={labelTrlCancelButton}
        onCancel={onCancelAction}
      />
    )
  }

  private rejectExistingCustomer = (
    setFieldValue: (field: keyof IAdminCustomer & string, value: any, shouldValidate?: boolean) => void,
  ) => {
    // setFieldValue('email', '')
    this.setState({ isFieldPrivateEmailValidated: false })
  }

  private handleUseExistingCustomer = (customer: IAdminCustomer | undefined) => {
    if (customer) {
      this.props.onLookupChange(customer)
      this.setState({ isFieldPrivateEmailValidated: true })
    }
  }

  private acceptCustomerVatInfo = async (
    setFieldValue: (field: keyof IAdminCustomer & string, value: any, shouldValidate?: boolean) => void,
  ) => {
    const customer = this.state.vatLookupCustomer!
    this.closeDialog()

    setFieldValue('cvr', customer.cvr)
    setFieldValue('companyName', customer.companyName)
    setFieldValue('address', customer.address)
    setFieldValue('zip', customer.zip)
    setFieldValue('city', customer.city)
    setFieldValue('email', customer.email)
    setFieldValue('phone', customer.phone)
    setFieldValue('name', customer.name)

    if (customer) {
      this.props.onChange(customer, false)
    }
  }

  private closeDialog = () => this.setState({ isDialogOpen: false })
}

export default withStyles(styles)(ContractFlowCustomerForm)
