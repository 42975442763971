import { Theme, WithStyles, withStyles } from '@material-ui/core'
import { Search as SearchIcon } from '@material-ui/icons'
import SpinnerButton from 'components/Mui/SpinnerButton'
import Typography from 'components/Typography'
import { IReturnArgsVehicleLicenseLookup } from 'pages/admin/ContractFlowPage/Details'
import React from 'react'
import { compose } from 'recompose'
import { createPanelStyles } from 'theme'
import { t } from 'translations/translationFunctions'

interface IProps {
  vin: string
  onAdvancedVehicleLookup: () => Promise<IReturnArgsVehicleLicenseLookup>
}

const styles = (theme: Theme) =>
  createPanelStyles(theme, {
    buttonContainer: {
      marginTop: theme.spacing(2),
    },
  })

type TProps = WithStyles<typeof styles> & IProps

interface IState {
  loading: boolean
}

class AdvancedLookup extends React.Component<TProps, IState> {
  public constructor(props: TProps) {
    super(props)
    this.state = {
      loading: false,
    }
  }

  render() {
    const { vin, classes } = this.props
    const { loading } = this.state

    return (
      <div>
        <div>
          <Typography variant="subtitle">
            {t('Missing or incorrect vehicle information - do an advanced search')}
          </Typography>
          <Typography variant="subtitle">
            {t('Advanced search uses the VIN: %vin', {
              vin,
            })}
          </Typography>
        </div>
        <div className={classes.buttonContainer}>
          <SpinnerButton
            className={classes.btnSave}
            color="primary"
            variant="contained"
            onClick={this.handleAdvancedVehicleLookup}
            showSpinner={loading}
            IconComponent={SearchIcon}
          >
            {t('Search')}
          </SpinnerButton>
        </div>
      </div>
    )
  }

  private handleAdvancedVehicleLookup = async () => {
    this.setState({ loading: true })
    await this.props.onAdvancedVehicleLookup()
    this.setState({ loading: false })
  }
}

export default compose<TProps, IProps>(withStyles(styles))(AdvancedLookup)
