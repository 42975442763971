import { createStyles, withStyles, WithStyles } from '@material-ui/core/styles'
import {
  ContractType,
  FuelType,
  IAdminCustomer,
  IContractCreationData,
  IGenericContractOptionResponse,
} from '@omnicar/sam-types'
import { IContractProviderInfo } from '@omnicar/sam-types/types/admin/contractProvider'
import ActionTypes from 'actions/ActionTypes'
import { IContractDisplayConfigSetHiddenVAT, setHiddenVAT } from 'actions/contractDisplayConfigActions'
import {
  contractFlowReset,
  contractFlowUpdate,
  contractFlowUpdateContractType,
  contractFlowUpdateState,
  IContractFlowReset,
  IContractFlowUpdate,
  IContractFlowUpdateContractType,
  IContractFlowUpdateState,
} from 'actions/contractFlowActions'
import { getContractAdjustment, getContractCreationData, getFuelTypes } from 'api/api'
import { IS_DEBUG_SLIDER_PRINT } from 'components/admin/Contract/Flow/DurationMileageVer2'
import ContractFlowStatusBar from 'components/admin/Contract/Flow/StatusBar'
import { Page, PageContent, PageHeader } from 'components/Mui/Page'
import _ from 'lodash'
import React from 'react'
import { connect } from 'react-redux'
import { RouteComponentProps } from 'react-router'
import { IRootState } from 'reducers/initialState'
import { Dispatch } from 'redux'
import { contractDetailsPath } from 'routes/paths'
import { AppContext } from 'store/appContext'
import { tFuelType } from 'translations/translationFunctions'
import {
  ContractFlowActivePanel,
  ContractFlowActiveStep,
  FreeContractTab,
  IContractFlow,
  IContractFlowState,
} from 'types/contractFlow'
import { checkWhetherHideVAT } from 'utils/contract'
import { checkIfOptionsHasWarranty, mapAdjustContract } from 'utils/contractFlow'
import browserHistory from 'utils/history'
import { getProvider } from 'utils/localStorage'
import { debugPrint } from 'utils/miscs'
import { getObjectDiff } from '../../../utils/miscs'
import ContractFlowPageDetails from './Details'
import ContractFlowPagePayment from './Payment'

interface IReducerProps {
  contractFlow: IContractFlow
}

interface IActionProps {
  contractFlowReset: () => IContractFlowReset
  contractFlowUpdate: (contract: Partial<IContractFlow>) => IContractFlowUpdate
  contractFlowUpdateState: (state: Partial<IContractFlowState>) => IContractFlowUpdateState
  contractFlowUpdateContractType: (contractType: ContractType) => IContractFlowUpdateContractType
  setHiddenVAT: (hiddenVAT: boolean) => IContractDisplayConfigSetHiddenVAT
}

interface IPathParam {
  prettyIdentifier?: string
}

type TProps = RouteComponentProps<IPathParam> & IReducerProps & IActionProps & WithStyles<typeof styles>

interface IState {
  creationData: IContractCreationData | undefined
  loading: boolean
  valid: { customer: boolean }
  contractUpdates: number
  customerUpdates: number
  vehicleUpdates: number
  warrantyCreated: boolean
  hideWarrantyButton: boolean
  hasAvailableWarranties: boolean
  producyFuelTypes: FuelType[] | undefined
}

const initialState: IState = {
  creationData: undefined,
  loading: true,
  valid: { customer: false }, // should be true if contract.flowType === 'ADJUST'... how do we know
  contractUpdates: 0,
  customerUpdates: 0,
  vehicleUpdates: 0,
  warrantyCreated: false,
  hideWarrantyButton: true,
  hasAvailableWarranties: false,
  producyFuelTypes: [],
}

const styles = () =>
  createStyles({
    statusBar: {
      flexGrow: 1,
    },
    margin: {
      marginTop: '103px',
    },
  })

class ContractFlowPage extends React.Component<TProps, IState> {
  public state: IState = { ...initialState }

  public componentDidMount() {
    this.init()
    this.loadProductFuelTypes()
  }

  public componentDidUpdate(prevProps: TProps) {
    if (
      prevProps.match.params.prettyIdentifier !== this.props.match.params.prettyIdentifier ||
      (prevProps.contractFlow.flowType === 'ADJUST' && !this.props.match.params.prettyIdentifier)
    ) {
      this.props.contractFlowReset()
      this.init()
    }
  }

  public render() {
    const { classes, contractFlow, match } = this.props

    const {
      creationData,
      contractUpdates,
      warrantyCreated,
      loading,
      customerUpdates,
      valid,
      vehicleUpdates,
      producyFuelTypes,
      hasAvailableWarranties,
    } = this.state
    const { active, step } = contractFlow.state
    const freeContract = contractFlow.contractType === 'CUSTOM'
    const productContract = contractFlow.contractObjectType === 'Product'
    let activeTab = productContract ? 'PRODUCT' : contractFlow.state.activeTab

    return (
      <AppContext.Consumer>
        {({ role, providerInfo, locale }) => {
          return (
            <Page>
              <PageHeader className={classes.margin}>
                <ContractFlowStatusBar className={classes.statusBar} step={step} freeContract={freeContract} />
              </PageHeader>
              <PageContent>
                {step === ContractFlowActiveStep.details && (
                  <ContractFlowPageDetails
                    activePanel={active}
                    activeTab={activeTab || 'VEHICLE'}
                    onActiveTabChange={this.handleActiveTabChange}
                    userRole={role}
                    contract={contractFlow}
                    creationData={creationData}
                    freeContract={freeContract}
                    productContract={productContract}
                    onActivePanelChange={this.handleActivePanelChange}
                    onChange={this.handleContractFlowChange}
                    onFreeContract={this.handleFreeContract}
                    onNext={this.handlePaymentStep}
                    onResetContract={this.handleRestartFlow}
                    prettyIdentifier={match.params.prettyIdentifier}
                    onCustomerChange={this.handleCustomerChange}
                    onCustomerLockedChange={this.handleCustomerLockedChange}
                    onContractUpdate={this.handleContractUpdate}
                    valid={valid}
                    contractUpdates={contractUpdates}
                    vehicleUpdates={vehicleUpdates}
                    customerUpdates={customerUpdates}
                    onWarrantyCreated={this.handleWarrantyCreated}
                    warrantyCreated={warrantyCreated}
                    loading={loading}
                    productFuelTypes={producyFuelTypes}
                    hasAvailableWarranties={hasAvailableWarranties}
                    locale={locale}
                  />
                )}
                {step >= ContractFlowActiveStep.payment && (
                  <ContractFlowPagePayment
                    activePanel={active}
                    contract={contractFlow}
                    providerInfo={providerInfo}
                    creationData={creationData}
                    freeContract={freeContract}
                    onActivePanelChange={this.handleActivePanelChange}
                    onBack={this.handleDetailsStep}
                    onChange={this.handleContractFlowChange}
                    onFinalStep={this.handleFinalStep}
                    onFreeContract={this.handleFreeContract}
                    onResetContract={this.handleRestartFlow}
                    prettyIdentifier={this.props.match.params.prettyIdentifier}
                    contractFlowReset={this.props.contractFlowReset}
                    onCustomerChange={this.handleCustomerChange}
                    onCustomerLockedChange={this.handleCustomerLockedChange}
                    onContractUpdate={this.handleContractUpdate}
                    valid={valid}
                    contractUpdates={contractUpdates}
                    vehicleUpdates={vehicleUpdates}
                    customerUpdates={customerUpdates}
                    warrantyCreated={warrantyCreated}
                  />
                )}
              </PageContent>
            </Page>
          )
        }}
      </AppContext.Consumer>
    )
  }

  private init = () => {
    this.setState({ loading: true })
    const { params } = this.props.match

    if (params.prettyIdentifier) {
      this.initAdjustData(params.prettyIdentifier)
    } else {
      this.initCreateData()
    }
  }

  private initAdjustData = async (prettyIdentifier: string) => {
    const response = await getContractAdjustment(prettyIdentifier)
    if (response.data && response.statusCode === 200) {
      const contract = mapAdjustContract(response.data)

      if (contract.template || contract.options) {
        const optionsForCheck: IGenericContractOptionResponse[] = [
          ...(contract.template?.properties ?? []),
          ...contract.options,
        ]
        this.updateHiddenVAT(optionsForCheck)
      }

      this.props.contractFlowUpdate({
        ...contract,
        previousContractPrice: contract.payments.contractCost,
      })
      this.setState({ loading: false })
    } else {
      browserHistory.push(contractDetailsPath(prettyIdentifier))
    }
  }

  private loadProductFuelTypes = async () => {
    if (!this.state.producyFuelTypes || this.state.producyFuelTypes.length < 1) {
      const provider: IContractProviderInfo | undefined = getProvider()
      const isoCountry: any = !(provider && provider.country) ? null : (provider.country as string)
      const response = await getFuelTypes(isoCountry, true, true)
      if (response.data) {
        const fuelTypesWithCaptions = response.data.map((item) => ({
          name: item.name,
          id: item.id,
          caption: tFuelType(item.name),
        }))
        const sortedFuelTypes = _.sortBy(fuelTypesWithCaptions, 'caption')
        this.setState({ producyFuelTypes: sortedFuelTypes })
      }
    }
  }

  private initCreateData = async () => {
    const creationData = await getContractCreationData()

    this.setState({ creationData: creationData.data, loading: false })
  }

  private handleCustomerChange = (customer: IAdminCustomer, valid: boolean) => {
    const wasValid = this.state.valid.customer

    if ((wasValid && !valid) || (!wasValid && !valid)) {
      this.handleActivePanelChange(ContractFlowActivePanel.customer)
    } else if ((!wasValid && valid) || (wasValid && valid)) {
      this.handleActivePanelChange(ContractFlowActivePanel.paymentGateway)
    }

    this.setState({
      valid: { ...this.state.valid, customer: valid },
      contractUpdates: this.state.contractUpdates + 1,
    })

    this.handleContractFlowChange({
      customer,
      invoiceCustomer: { ...customer },
      customerId: customer.id ? customer.id : undefined,
      invoiceCustomerId: customer.id ? customer.id : undefined,
    })
  }

  private handleContractUpdate = () => {
    this.setState({ contractUpdates: this.state.contractUpdates + 1 })
  }

  private handleWarrantyCreated = () => {
    this.setState({ warrantyCreated: true })
  }

  private handleCustomerLockedChange = (locked: boolean) => {
    this.handleContractFlowChange({
      state: {
        ...this.props.contractFlow.state,
        customerSearched: locked,
      },
    })
  }

  private handleActiveTabChange = (newTab?: FreeContractTab) => {
    if (newTab) {
      this.props.contractFlowUpdate({
        state: {
          ...this.props.contractFlow.state,
          activeTab: newTab,
          active: ContractFlowActivePanel.vehicle,
          options: [],
        },
      })
    }
  }

  private handleFreeContract = (hideWarrantyButton?: boolean) => {
    if (hideWarrantyButton) this.setState({ hideWarrantyButton })

    this.props.contractFlowUpdateContractType('CUSTOM')
    this.props.contractFlowUpdate({
      state: {
        ...this.props.contractFlow.state,
        licenseSkip: false,
      },
    })

    this.handleActivePanelChange(ContractFlowActivePanel.vehicle)
  }

  private handleRestartFlow = () => {
    this.props.contractFlowReset()
  }

  private handleActivePanelChange = (active: ContractFlowActivePanel) => {
    this.props.contractFlowUpdateState({ active })
  }

  private handlePaymentStep = () => {
    this.props.contractFlowUpdateState({
      step: ContractFlowActiveStep.payment,
      // If we're adjusting, the customer will always be valid
      active:
        this.props.contractFlow.flowType === 'ADJUST'
          ? ContractFlowActivePanel.paymentGateway
          : ContractFlowActivePanel.customer,
    })
  }

  private handleDetailsStep = () => {
    this.props.contractFlowUpdateState({
      activeTab: this.props.contractFlow.state.activeTab,
      step: ContractFlowActiveStep.details,
      active: ContractFlowActivePanel.options,
    })
  }

  // @NOTE - Having a isFinal argument on a "handleFinalStep" method can seem silly
  // but it ensures resetting the step back to payment in case user decides to close the
  // dialog overlays that makes up for the final "send" step
  private handleFinalStep = (isFinal: boolean) => {
    this.props.contractFlowUpdateState({
      step: isFinal ? ContractFlowActiveStep.send : ContractFlowActiveStep.payment,
    })
  }

  private handleContractFlowChange = async (values: Partial<IContractFlow>) => {
    debugPrint(IS_DEBUG_SLIDER_PRINT, '*')
    debugPrint(IS_DEBUG_SLIDER_PRINT, 'ContractFlowPage: -> handleContractFlowChange(..): value:')
    debugPrint(IS_DEBUG_SLIDER_PRINT, values)

    const diff: string[] = getObjectDiff(this.props.contractFlow, values)
    debugPrint(IS_DEBUG_SLIDER_PRINT, 'AN: diff:')
    debugPrint(IS_DEBUG_SLIDER_PRINT, diff)

    const { options: stateOptions, template: stateTemplate } = this.props.contractFlow
    const { options: newOptions, template: newTemplate } = values
    const optionsForCheck: IGenericContractOptionResponse[][] = []
    // If updated options compare template properties (from state) with options.
    if (newOptions) {
      optionsForCheck.push(newOptions)
      optionsForCheck.push(stateTemplate?.properties || [])
    }
    // If updated template compare template properties with options (from state).
    if (newTemplate) {
      optionsForCheck.push(stateOptions)
      optionsForCheck.push(newTemplate.properties)
    }

    if (newOptions || newTemplate) {
      // TODO: use Array.prototype.flat function instead of reduce. For this we need to update tsconfig to es2019
      this.updateHiddenVAT(optionsForCheck.reduce((acc, val) => acc.concat(val), []))
    }

    this.props.contractFlowUpdate({ ...values })

    // TODO: check if vehicle or customer data actually have changed before increase update count
    this.setState({ vehicleUpdates: this.state.vehicleUpdates + 1, customerUpdates: this.state.customerUpdates + 1 })
  }

  private updateHiddenVAT = (options: IGenericContractOptionResponse[]) =>
    this.props.setHiddenVAT(checkWhetherHideVAT(checkIfOptionsHasWarranty(options)))
}

const mapStateToProps = (state: IRootState) => ({
  contractFlow: state.contractFlow,
})

const mapDispatchToProps = (dispatch: Dispatch<ActionTypes>) => ({
  contractFlowReset: () => dispatch(contractFlowReset()),
  contractFlowUpdate: (values: Partial<IContractFlow>) => dispatch(contractFlowUpdate(values)),
  contractFlowUpdateState: (state: IContractFlowState) => dispatch(contractFlowUpdateState(state)),
  contractFlowUpdateContractType: (contractType: ContractType) =>
    dispatch(contractFlowUpdateContractType(contractType)),
  setHiddenVAT: (hiddenVAT: boolean) => dispatch(setHiddenVAT(hiddenVAT)),
})

export default withStyles(styles)(connect(mapStateToProps, mapDispatchToProps)(ContractFlowPage))
