import { createStyles, Theme, WithStyles, withStyles } from '@material-ui/core/styles'
import classNames from 'classnames'
import Typography from 'components/Typography'
import React from 'react'
import { PanelContent, Panel } from 'components/Mui/Panel'
import { Card } from 'components/Mui/Card'
import { CardContent, Button } from '@material-ui/core'
import { Warning as WarningIcon, Cancel as CancelIcon } from '@material-ui/icons'
import { compose } from 'recompose'

interface IOwnProps {
  message: string
  closeable?: boolean
  handleClose?: () => void
}

type TProps = WithStyles<typeof styles> & IOwnProps

const styles = ({ spacing }: Theme) =>
  createStyles({
    warningCard: {
      display: 'flex',
      backgroundColor: '#FFC828', // orange = #FFA808
      marginTop: 0,
      marginBottom: 0,
      paddingTop: 0,
      paddingBottom: 0,
      opacity: 0.97,
    },
    warningIconContent: {
      paddingLeft: spacing(1.5),
      paddingRight: spacing(2),
      marginTop: 'auto',
      marginBottom: 'auto',
    },
    warningIcon: {
      fontSize: 32,
      color: '#FFF',
      opacity: 0.9,
    },
    warningMessageContent: {
      paddingLeft: 0,
      marginTop: 0,
      marginBottom: 0,
      '&:last-child': {
        marginTop: 'auto',
        marginBottom: 'auto',
        paddingTop: spacing(1),
        paddingBottom: spacing(1),
      },
    },
    closeButtonContent: {
      margin: 0,
      padding: 0,
      paddingTop: spacing(1),
      paddingRight: spacing(1),
    },
    warningMessageText: {
      letterSpacing: '0.05em',
      color: '#111111',
    },
    closeButton: {
      display: 'flex',
      marginLeft: 'auto',
      padding: 0,
      minWidth: '22px',
      minHeight: '22px',
      borderRadius: '50%',
    },
    closeIcon: {
      fontSize: 14,
    },
  })

const WarningMessage: React.SFC<TProps> = ({ classes, closeable, handleClose, message }) => {
  return (
    <div>
      <Panel>
        <PanelContent>
          <Card className={classNames(classes.warningCard)}>
            <CardContent className={classNames(classes.warningIconContent)}>
              <WarningIcon className={classNames(classes.warningIcon)} />
            </CardContent>
            <CardContent className={classNames(classes.warningMessageContent)}>
              <Typography variant="subtitle1">
                <span className={classNames(classes.warningMessageText)}>{message}</span>
              </Typography>
            </CardContent>
            <CardContent className={classNames(classes.closeButtonContent)}>
              {closeable && (
                <Button onClick={handleClose} className={classes.closeButton}>
                  <CancelIcon className={classes.closeIcon} />
                </Button>
              )}
            </CardContent>
          </Card>
        </PanelContent>
      </Panel>
    </div>
  )
}

export default compose<TProps, IOwnProps>(withStyles(styles))(WarningMessage)
